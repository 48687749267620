import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import PreviewCompatibleImage from './PreviewCompatibleImage'

const Pricing = ({ data }) => (
  <div className="columns">
    {data.map(price => (
      <div key={price.plan} className="column" style={{ margin: 20, border: '1px solid #333', borderRadius: 15 }}>
        <section className="section" style={{ padding: 15 }}>
          <h4 className="has-text-centered has-text-weight-bold is-size-1" style={{ margin: 10 }}>
            {price.plan}
          </h4>
          <p className="has-text-weight-semibold has-text-centered" style={{ marginLeft: 10, marginRight: 10 }}>{price.description}</p>
          {price.items &&
            <ul style={{ listStyle: 'none', margin: 0 }} className="has-text-centered">
              {price.items.map(item => (
                <li key={item} className="is-size-6">
                  {item}
                </li>
              ))}
            </ul>}
          <h2 className="is-size-2 has-text-weight-bold has-text-centered" style={{ marginTop: 20 }}>
            ${price.price}
          </h2>
          <p className="has-text-centered">
            <Link to="/contacto" className="button">
              Reservá tu espacio
            </Link>
          </p>
          {price.planimage &&
            <PreviewCompatibleImage
              imageInfo={{
                image: price.planimage,
                alt: `Image thumbnail for plan ${price.plan}`,
              }}
            />}
        </section>
      </div>
    ))}
  </div>
)

Pricing.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      plan: PropTypes.string,
      price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      description: PropTypes.string,
      items: PropTypes.array,
      planimage: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    })
  ),
}

export default Pricing
